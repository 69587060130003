import { Button, FormGroup, InputGroup, Intent, Menu, MenuItem, TagInput } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

const StyledFieldGoogleAdSearchStandard = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    .keywords {
        grid-column-start: 1;
        grid-column-end: 3;
    }
    @media screen and (max-width: 800px) {
        display: block;
    }
`;

const StyledGoogleAdsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 15px;
`;

const ErrorMessage = styled.p`
    color: red;
    font-size: 12px;
`;
const StyledSiteLink = styled.div`
    padding: 5px 10px;
`;

const FormSiteLink = styled.div`
    display: flex;
    gap: 10px;
    input {
        width: 50%;
    }
    margin-bottom: 10px;
`;
const SiteLinkContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: #f8f9fa;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
`;

const SiteLinkText = styled.div`
    flex-grow: 1;
    strong {
        border: 1px solid #215db1;
        border-radius: 10px;
        padding: 1px 10px;
        color: #0060ff;
        margin-right: 15px;
    }
`;

const RemoveButton = styled.button`
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
`;

const HEADLINE_CHAR_LIMIT = 30;
const HEADLINE_COUNT_LIMIT = 15;
const LONG_HEADLINE_COUNT_LIMIT = 5;
const LONG_HEADLINE_CHAR_LIMIT = 90;
export const DESCRIPTION_CHAR_LIMIT = 90;
const DESCRIPTION_COUNT_LIMIT = 5;

const buildForGroupProps = ({ errors, name, dataValue, maxLength, placeHolder }) => {
    return {
        labelFor: `gad-${name}`,
        helperText: errors[name] || placeHolder || `${(dataValue || "").length}/${maxLength}`,
        intent: (dataValue || "").length > maxLength ? Intent.DANGER : Intent.NONE,
    };
};
const handleInputChange = (index, type, role, value, onChange, newValue) => {
    const filteredValues = (value || []).filter((elt) => elt.type === type && elt.role === role);
    const globalIndex = (value || []).findIndex(
        (elt, i) => elt.type === type && elt.role === role && filteredValues.indexOf(elt) === index
    );

    const updatedValues = (value || []).map((elt, i) => {
        if (i === globalIndex) {
            return { ...elt, value: newValue };
        }
        return elt;
    });

    onChange(updatedValues);
};

const FieldGoogleTexts = ({ value, onChange, disabled, loading, errors }) => {
    const [siteLink, setSiteLink] = useState(null);
    const [error, setError] = useState("");
    const handleSiteLinkChange = (field, newValue) => {
        setSiteLink({ ...siteLink, [field]: newValue });
    };

    const handleValidation = () => {
        const { text, description, url } = siteLink;
        if (text && description && url) {
            const newValue = {
                value: JSON.stringify(siteLink),
                type: "sitelink",
                role: "sitelinks",
            };
            onChange([...value, newValue]);
            setSiteLink(null);
            setError("");
        } else {
            setError("All fields must be filled out before submitting.");
        }
    };
    const handleRemove = (indexToRemove) => {
        const updatedValue = value.filter((_, index) => index !== indexToRemove);
        onChange(updatedValue);
    };
    useEffect(() => {
        if (!value?.find((v) => v.type === "keyword"))
            onChange([...value, { value: "", type: "keyword", role: "keywords" }]);
    }, [onChange, value]);

    const dataValue = useMemo(() => value || {}, [value]);
    const handleCopyKeywords = useCallback(async () => {
        const permission = await navigator.permissions.query({ name: "clipboard-write" });
        if (permission.state === "denied") {
            throw new Error("Not allowed to write clipboard.");
        }

        await navigator.clipboard.writeText(dataValue.find((v) => v.type === "keyword").value);
    }, [dataValue]);
    return (
        <StyledGoogleAdsContainer>
            <StyledFieldGoogleAdSearchStandard>
                {(value || [])
                    .filter((elt) => elt.type === "headline")
                    .map((v, index) => {
                        return (
                            <FormGroup
                                label={`Headline ${index + 1}`}
                                {...buildForGroupProps({
                                    errors,
                                    name: "headline",
                                    dataValue: v.value,
                                    maxLength: HEADLINE_CHAR_LIMIT,
                                })}
                            >
                                <InputGroup
                                    id="gad-headline1"
                                    disabled={disabled || loading}
                                    value={v.value || ""}
                                    onChange={(e) =>
                                        handleInputChange(
                                            index,
                                            "headline",
                                            "headlines",
                                            value,
                                            onChange,
                                            e.target.value
                                        )
                                    }
                                    maxLength={HEADLINE_CHAR_LIMIT}
                                />
                            </FormGroup>
                        );
                    })}
                {(value || []).filter((elt) => elt.type === "headline").length < HEADLINE_COUNT_LIMIT && (
                    <Button
                        onClick={() => onChange([...value, { value: "", type: "headline", role: "headlines" }])}
                        text="Add Headline"
                        icon="plus"
                    />
                )}
            </StyledFieldGoogleAdSearchStandard>
            <StyledFieldGoogleAdSearchStandard>
                {(value || [])
                    .filter((elt) => elt.type === "long_headline")
                    .map((v, index) => {
                        return (
                            <FormGroup
                                label={`Long Headline ${index + 1}`}
                                {...buildForGroupProps({
                                    errors,
                                    name: "long_headline",
                                    dataValue: v.value,
                                    maxLength: LONG_HEADLINE_CHAR_LIMIT,
                                })}
                            >
                                <InputGroup
                                    id="gad-headline1"
                                    disabled={disabled || loading}
                                    value={v.value || ""}
                                    onChange={(e) =>
                                        handleInputChange(
                                            index,
                                            "long_headline",
                                            "long_headlines",
                                            value,
                                            onChange,
                                            e.target.value
                                        )
                                    }
                                    maxLength={LONG_HEADLINE_CHAR_LIMIT}
                                />
                            </FormGroup>
                        );
                    })}
                {(value || []).filter((elt) => elt.type === "long_headline").length < LONG_HEADLINE_COUNT_LIMIT && (
                    <Button
                        onClick={() =>
                            onChange([...value, { value: "", type: "long_headline", role: "long_headlines" }])
                        }
                        text="Add Long Headline"
                        icon="plus"
                    />
                )}
            </StyledFieldGoogleAdSearchStandard>
            <StyledFieldGoogleAdSearchStandard>
                {(value || [])
                    .filter((elt) => elt.type === "description")
                    .map((v, index) => {
                        return (
                            <FormGroup
                                label={`Description ${index + 1}`}
                                {...buildForGroupProps({
                                    errors,
                                    name: "description",
                                    dataValue: v.value,
                                    maxLength: DESCRIPTION_CHAR_LIMIT,
                                })}
                            >
                                <InputGroup
                                    id="gad-description1"
                                    disabled={disabled || loading}
                                    value={v.value || ""}
                                    onChange={(e) =>
                                        handleInputChange(
                                            index,
                                            "description",
                                            "descriptions",
                                            value,
                                            onChange,
                                            e.target.value
                                        )
                                    }
                                    maxLength={DESCRIPTION_CHAR_LIMIT}
                                    rows="4"
                                    fill
                                />
                            </FormGroup>
                        );
                    })}
                {(value || []).filter((elt) => elt.type === "description").length < DESCRIPTION_COUNT_LIMIT && (
                    <Button
                        onClick={() => onChange([...value, { value: "", type: "description", role: "descriptions" }])}
                        text="Add Description"
                        icon="plus"
                    />
                )}
            </StyledFieldGoogleAdSearchStandard>
            {(value || [])
                .filter((elt) => elt.type === "keyword")
                .map((v, index) => {
                    return (
                        <FormGroup className="keywords" label="Keywords">
                            <TagInput
                                id="gad-keywords"
                                disabled={disabled || loading}
                                values={v.value.split(",")}
                                onChange={(values) => {
                                    const newValue = values.filter((val) => val.trim() !== "").join(",");
                                    handleInputChange(index, "keyword", "keywords", value, onChange, newValue);
                                }}
                                placeholder="Separate values with 'enter'..."
                                tagProps={{
                                    minimal: true,
                                }}
                                addOnBlur
                                addOnPaste
                                rightElement={
                                    <Popover2
                                        interactionKind="click"
                                        placement="bottom"
                                        content={
                                            <Menu>
                                                <MenuItem
                                                    text="Copy all"
                                                    icon="duplicate"
                                                    onClick={handleCopyKeywords}
                                                />
                                                <MenuItem
                                                    text="Clear"
                                                    icon="cross"
                                                    disabled={disabled}
                                                    onClick={() =>
                                                        handleInputChange(
                                                            index,
                                                            "keyword",
                                                            "keywords",
                                                            value,
                                                            onChange,
                                                            ""
                                                        )
                                                    }
                                                />
                                            </Menu>
                                        }
                                        renderTarget={({ isOpen, ref, ...targetProps }) => (
                                            <Button {...targetProps} elementRef={ref} icon="chevron-down" />
                                        )}
                                    />
                                }
                            />
                        </FormGroup>
                    );
                })}

            <StyledSiteLink>
                <h4>SiteLinks</h4>
                {siteLink && (
                    <FormSiteLink>
                        <InputGroup
                            type="text"
                            placeholder="SiteLink Text"
                            value={siteLink.text}
                            onChange={(e) => handleSiteLinkChange("text", e.target.value)}
                        />
                        <InputGroup
                            type="text"
                            placeholder="Description"
                            value={siteLink.description}
                            onChange={(e) => handleSiteLinkChange("description", e.target.value)}
                        />
                        <InputGroup
                            type="url"
                            placeholder="URL"
                            value={siteLink.url}
                            onChange={(e) => handleSiteLinkChange("url", e.target.value)}
                        />
                        <Button onClick={handleValidation}>Add</Button>
                    </FormSiteLink>
                )}

                {error && <ErrorMessage>{error}</ErrorMessage>}
                <div>
                    {value
                        .filter((elt) => elt.type === "sitelink")
                        .map((link, index) => {
                            const parsedLink = JSON.parse(link.value);
                            return (
                                <SiteLinkContainer key={index}>
                                    <SiteLinkText>
                                        <strong>{parsedLink.text} </strong>{" "}
                                        <a target="_blank" rel="noreferrer" href={parsedLink.url}>
                                            {parsedLink.url}
                                        </a>
                                    </SiteLinkText>
                                    <RemoveButton onClick={() => handleRemove(index)}>Remove</RemoveButton>
                                </SiteLinkContainer>
                            );
                        })}
                </div>
                {(value || []).filter((elt) => elt.type === "sitelink").length < HEADLINE_COUNT_LIMIT && (
                    <Button
                        onClick={() => setSiteLink({ text: "", description: "", url: "" })}
                        text="Add SiteLink"
                        icon="plus"
                    />
                )}
            </StyledSiteLink>
        </StyledGoogleAdsContainer>
    );
};

FieldGoogleTexts.propTypes = {
    value: PropTypes.arrayOf(PropTypes.shape({})),
    emptyValue: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    errors: PropTypes.shape({}),
};

FieldGoogleTexts.defaultProps = {
    value: [],
    emptyValue: null,
    onChange: null,
    disabled: false,
    loading: false,
    errors: {},
};

export default FieldGoogleTexts;
